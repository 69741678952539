import axios from 'axios';

import { ConfirmPasswordProps, LoginProps, PasswordResetResponseType, UserType } from './types/reducers/auth';
import {
    ScoresDataType,
    MapSuggestion,
    MapCoordinates,
    GISLayersResponseType,
    WaterPipeResponseType,
    WastewaterPipeResponseType,
} from './types/reducers/mapData';
import { DistrictType, DistrictsDataType, WastewaterSystemsDataType } from './types/reducers/districts';
import { TownsDataType } from './types/reducers/towns';
import {
    ProjectDataType,
    PipeDetails,
    SelectedWeightsType,
    ProjectLimitDataType,
    ProjectStatusType,
    ProjectCsvResponseType,
} from './types/reducers/projects';
import { URLs, investmentCategoryLabel } from './util/constants';
import { getGeocodeUrl, getSuggestUrl } from './utils';
import { NotificationType, NotificationUpdate } from './types/reducers/notifications';
import { DashboardDataType } from './types/reducers/dashboard';
import { GeocodeExtent, GeocodeLocation, GetProjectsResponse, Paginator } from './types/api';

axios.defaults.baseURL = '/api';
// Used to make authenticated HTTP requests to Django
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';

export const API = axios.create({
    headers: {
        credentials: 'same-origin',
    },
});

export interface PipesQueryType {
    district?: DistrictType;
    wastewater_system?: string;
    projectUniversalId?: ProjectDataType['universal_id'];
}

export const pipesQueryString = (options: PipesQueryType): string => {
    const { district, wastewater_system, projectUniversalId } = options;
    const queries: string[][] = [];

    if (district) {
        queries.push(['district', district]);
    }
    if (wastewater_system) {
        queries.push(['wastewater_system', wastewater_system]);
    }
    if (projectUniversalId) {
        queries.push(['project', `${projectUniversalId}`]);
    }
    const params = new URLSearchParams(queries);
    return `?${params}`;
};

export async function getWaterPipesData(options?: PipesQueryType): Promise<WaterPipeResponseType> {
    const queryString = options ? pipesQueryString(options) : undefined;
    return API.get(URLs.makeWaterPipesURL(queryString)).then((response) => response.data);
}

export async function getWastewaterPipesData(options?: PipesQueryType): Promise<WastewaterPipeResponseType> {
    const queryString = options ? pipesQueryString(options) : undefined;
    return API.get(URLs.makeWastewaterPipesURL(queryString)).then((response) => response.data);
}

export async function getPipesDetails(pipeIds: string[], project: ProjectDataType): Promise<PipeDetails> {
    const url =
        project.asset_category == 'Water' ? URLs.makeWaterPipesDetailsURL() : URLs.makeWastewaterPipesDetailsURL();
    return API.post(url, { pipeIds, pipeVersionId: project.pipe_version }).then((response) => response.data);
}

export async function getWaterPipesScoresData(
    options: PipesQueryType,
    weights: SelectedWeightsType,
): Promise<ScoresDataType> {
    const queryString = pipesQueryString(options);
    const weightParams = [];
    for (const [key, value] of Object.entries(weights)) {
        weightParams.push(`&${key}=${value}`);
    }
    const weightParamQuery = weightParams.join('');
    return API.get(URLs.makeWaterPipesImpactScoresURL(`${queryString}${weightParamQuery}`)).then(
        (response) => response.data,
    );
}

export async function selectPipes(
    project: ProjectDataType,
    bbox: number[],
): Promise<{ pipes: string[]; total_length: number }> {
    const url =
        project.asset_category == 'Water' ? URLs.makeSelectWaterPipesURL() : URLs.makeSelectWastewaterPipesURL();
    return API.post(url, { project, bbox }).then((response) => response.data);
}

export async function getDistricts(): Promise<DistrictsDataType> {
    return API.get(URLs.makeDistrictsURL()).then((response) => response.data);
}

export async function getWastewaterSystems(): Promise<WastewaterSystemsDataType> {
    return API.get(URLs.makeWastewaterSystemsURL()).then((response) => response.data);
}

export interface LocationQueryType {
    state: string;
    district: DistrictType;
    wastewater_system?: string;
}

export const locationQueryString = (options: LocationQueryType): string => {
    const { state, district, wastewater_system } = options;
    const queries: string[][] = [
        ['state', state],
        ['district', district],
    ];

    if (wastewater_system) {
        queries.push(['wastewater_system', wastewater_system]);
    }
    const params = new URLSearchParams(queries);
    return `?${params}`;
};

export async function getTowns(options: LocationQueryType): Promise<TownsDataType> {
    const queryString = locationQueryString(options);
    return API.get(URLs.makeTownsURL(queryString)).then((response) => response.data);
}

export async function getNotifications(): Promise<NotificationType[]> {
    return API.get(URLs.makeNotificationsURL()).then((response) => response.data);
}

export async function getProject(universal_id: string): Promise<ProjectDataType> {
    return API.get(URLs.makeProjectURL(universal_id))
        .then((response) => response.data)
        .then((data) => {
            const formattedData = {
                ...data,
                sr_weight: parseFloat(data.sr_weight),
                srs_weight: parseFloat(data.srs_weight),
                sfp_weight: parseFloat(data.sfp_weight),
                wq_weight: parseFloat(data.wq_weight),
                ls_weight: parseFloat(data.ls_weight),
                l_weight: parseFloat(data.l_weight),
            };
            return formattedData;
        });
}

export async function getProjectFoundationalFilingPeriods(): Promise<number[]> {
    return API.get(URLs.makeProjectFoundationalFilingPeriodURL()).then((response) => response.data);
}

export async function getProjectLimits(): Promise<ProjectLimitDataType> {
    return API.get(URLs.makeProjectLimitURL()).then((response) => response.data);
}

// Ensures each category only appears once, and that required categories are included
const cleanCategories = (categories: investmentCategoryLabel[]): investmentCategoryLabel[] => {
    const projectCategories: investmentCategoryLabel[] = categories ? categories : [];
    return Array.from(new Set(projectCategories));
};

export const cleanSRSWeight = (srs_weight: number) => (srs_weight ? srs_weight : 0);

const cleanImpactScore = (score: null | string | number): number => {
    let total_impact_score = score;

    if (!total_impact_score) {
        total_impact_score = 0.0;
    }

    if (typeof total_impact_score === 'string') {
        total_impact_score = parseFloat(total_impact_score);
    }

    total_impact_score = Math.round((total_impact_score + Number.EPSILON) * 10) / 10;

    return total_impact_score;
};

const cleanProject = (project: ProjectDataType): ProjectDataType => ({
    ...project,
    srs_weight: cleanSRSWeight(project.srs_weight),
    categories: cleanCategories(project.categories),
    total_length: Math.round(project.total_length ? project.total_length : 0),
    total_impact_score: cleanImpactScore(project.total_impact_score),
});

export async function postNewProject(project: ProjectDataType): Promise<ProjectDataType> {
    return API.post(URLs.makeProjectsURL(''), cleanProject(project)).then((response) => response.data);
}

export async function putProject(universal_id: string, project: ProjectDataType): Promise<ProjectDataType> {
    return API.put(URLs.makeProjectURL(universal_id), cleanProject(project)).then((response) => response.data);
}

export async function putSubmitProject(id: string): Promise<ProjectDataType> {
    return API.put(URLs.makeProjectSubmitURL(id)).then((response) => response.data);
}

export async function putResetProject(id: string): Promise<ProjectDataType> {
    return API.put(URLs.makeProjectResetUrl(id)).then((response) => response.data);
}

export async function getProjects(queryString: string): Promise<GetProjectsResponse> {
    return API.get(URLs.makeProjectsURL(queryString)).then((response) => response.data);
}

export async function getPaginatorData(queryString: string): Promise<Paginator> {
    return API.get(URLs.makePaginatorURL(queryString)).then((response) => response.data);
}

export async function getCsvRecords(queryString: string): Promise<ProjectCsvResponseType[]> {
    return API.get(URLs.makeProjectsCsvURL(queryString)).then((response) => response.data);
}

export async function getCreatedBy(): Promise<ProjectDataType[]> {
    return API.get(URLs.makeCreatedByURL()).then((response) => response.data);
}

export async function postEmailLogin({ email, password }: LoginProps): Promise<UserType> {
    return API.post(URLs.makeLoginURL(), { email, password }).then((response) => response.data);
}

export async function postResetPassword(email: string): Promise<PasswordResetResponseType> {
    return API.post(URLs.makeResetPasswordURL(), { email }).then((response) => response.data);
}

export async function postConfirmResetPassword({
    uid,
    token,
    newPassword,
    newPasswordConfirmation,
}: ConfirmPasswordProps): Promise<PasswordResetResponseType> {
    return API.post(URLs.makeConfirmResetPasswordURL(), {
        uid,
        token,
        new_password1: newPassword,
        new_password2: newPasswordConfirmation,
    }).then((response) => response.data);
}

export async function getLoginWithSessionId(): Promise<UserType> {
    return API.get(URLs.makeLoginURL()).then((response) => response.data);
}

export async function postLogoutUser(): Promise<string> {
    return API.post(URLs.makeLogoutURL()).then((response) => response.data);
}

export async function getGeocodeSuggestions(value: string): Promise<MapSuggestion[]> {
    return axios.get(getSuggestUrl(value)).then((response) => {
        if (!response.data.suggestions) {
            return [];
        }
        return response.data.suggestions;
    });
}

export async function getGeocodeResponse(label: string, value: string): Promise<MapCoordinates> {
    return axios.get(getGeocodeUrl(label, value)).then((response) => {
        if (response.status !== 200 || !response.data?.candidates?.length) {
            throw new Error('Error setting map search point');
        } else {
            const { location, extent }: { location: GeocodeLocation; extent: GeocodeExtent } =
                response.data.candidates[0];
            const { xmin, ymin, xmax, ymax } = extent;
            const searchPoint: MapCoordinates = {
                latitude: location.y,
                longitude: location.x,
                extent: [xmin, ymin, xmax, ymax],
            };

            return searchPoint;
        }
    });
}

export async function putNotificationStatuses(data: NotificationUpdate[]): Promise<NotificationType[]> {
    return axios.put(URLs.makeNotificationsURL(), data).then((response) => {
        if (response.status !== 200) {
            throw new Error('Error updating notification status');
        } else {
            return response.data;
        }
    });
}

export async function approveProject(
    universal_id: string | null,
    notes: string,
): Promise<{ status: ProjectStatusType }> {
    return API.put(URLs.makeProjectApprovalURL(universal_id), { notes }).then((response) => response.data);
}

export async function denyProject(universalId: string | null, notes: string): Promise<{ status: ProjectStatusType }> {
    return API.put(URLs.makeProjectDenialURL(universalId), { notes }).then((response) => response.data);
}

export async function completeProject(universalId: string | null, date: string): Promise<ProjectDataType> {
    return API.put(URLs.makeProjectCompleteURL(universalId), { date: date }).then((response) => response.data);
}

export async function revertProject(universalId: string | null): Promise<ProjectDataType> {
    return API.put(URLs.makeProjectRevertURL(universalId)).then((response) => response.data);
}

export async function deleteProject(universalId: string | null): Promise<ProjectDataType> {
    return API.delete(URLs.makeProjectDeletionURL(universalId)).then((response) => response.data);
}

export async function getDashboardHeader(queryString: string): Promise<DashboardDataType> {
    return API.get(URLs.makeDashboardHeaderURL(queryString)).then((response) => response.data);
}

export async function getGISLayers(state: string): Promise<GISLayersResponseType> {
    return API.get(URLs.getAllGISLayersURL(state)).then((response) => response.data);
}
