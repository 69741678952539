import { AxiosError } from 'axios';
import { createStandaloneToast } from '@chakra-ui/toast';
import orderBy from 'lodash/orderBy';
import { isNull, isUndefined, pick, uniq } from 'lodash';
import moment from 'moment';

import {
    SelectedWeightsType,
    PipeDetails,
    ProjectDataType,
    ProjectMostDataType,
    ProjectLimitDataType,
    ProjectStatusType,
    weightLabel,
    ProjectCsvDataType,
    ProjectCsvResponseType,
} from '../types/reducers/projects';
import { RootState } from '../store';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { MaybeUserType } from '../types/reducers/auth';
import env from './env';
import {
    FILTER_MODES,
    projectWeightLabels,
    investmentCategories,
    limitExceptionLabels,
    presetEstDurationOptions,
    presetEstimateOptions,
    weightsToInvestmentCategoryLabels,
    investmentCategoryLabel,
    waterPipeTableHeaderFields,
    wastewaterPipeTableHeaderFields,
    PROJECT_STATUS_DESCRIPTION,
} from './constants';
import { MapcallStatusType, OptionType, ProjectTypeType } from '../types/types';
import { statusApproved, statusDenied, statusDone, statusNeutral } from './styles';
import { RGBTuple, ScoresType, PipeDetailType } from '../types/reducers/mapData';

export const { ToastContainer, toast } = createStandaloneToast();
export const calcWeightSum = (weights: SelectedWeightsType): number =>
    Object.values(weights).reduce((sum, val) => sum + Number(val), 0);

export const getErrorMessage = (e: unknown, message: string): string => {
    return e instanceof AxiosError && e.response?.data?.detail !== undefined ? e.response?.data?.detail : message;
};

export const handleFieldErrors = (e: unknown): boolean => {
    const isUserError = e instanceof AxiosError && e.response?.status === 400;
    if (!isUserError) {
        return false;
    }
    const data = e instanceof AxiosError && e.response?.data;
    Object.keys(data || {}).map((field) => {
        toast({
            title: 'An error occurred.',
            description: `Error in ${field}: ${data[field].join(' ')}`,
            status: 'error',
            duration: 9000,
            isClosable: true,
        });
    });

    return true;
};

export const formatPipeDetailValue = (value: string | number | null | boolean | undefined, key: string) => {
    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
    } else if (value === undefined || value === null) {
        return 'N/A';
    } else if (typeof value !== 'number') {
        return value;
    }
    if (key === 'mainlength' || key == 'depth') {
        return value.toFixed(2);
    } else if (key === 'fflow_val') {
        return value.toFixed(0);
    } else {
        return value;
    }
};

export const handleDownloadCSV = (project: ProjectDataType, impactScores: ScoresType) => {
    const options = {
        filename: project.name,
        title: project.name,
        useKeysAsHeaders: true,
    };

    const tableHeaderFields =
        project.asset_category == 'Wastewater' ? wastewaterPipeTableHeaderFields : waterPipeTableHeaderFields;

    const data = Object.values(project.pipe_details).map((pipe_detail) => ({
        'Pipe Score': Number(impactScores[pipe_detail.gisuid]) >= 0 ? impactScores[pipe_detail.gisuid] : 'Not scored',
        ...Object.fromEntries(
            Object.entries(tableHeaderFields).map(([key, label]) => [
                label,
                formatPipeDetailValue(pipe_detail[key as keyof PipeDetailType], key),
            ]),
        ),
    }));

    const csvConfig = mkConfig(options);
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
};

export const userCanSaveProject = (user: RootState['auth']['user'], project: ProjectDataType) => {
    if (project.status && project.status === 'COMPLETE') return false;
    if (!user || !userCanEdit(user)) return false;
    if (!project.universal_id) return true;
    return project.created_by_id == user.id;
};

// Effectively same logic as user's ability to save,
// set under new variable name for clarity
export const userCanDeleteProject = (user: RootState['auth']['user'], project: ProjectDataType) =>
    userCanSaveProject(user, project);

export const userCanRevertCompletedProject = (user: RootState['auth']['user'], project: ProjectDataType) => {
    if (project.status && project.status != 'COMPLETE') return false;
    if (!user || !userCanEdit(user)) return false;
    if (!project.universal_id) return false;
    return project.created_by_id == user.id;
};

export const isWeightTotalInvalid = (weights: SelectedWeightsType) => Math.abs(calcWeightSum(weights) - 1) > 0.00001;

export const formatProjectLength = (total_length: number | null) => {
    return total_length ? Math.round(total_length).toLocaleString('en-US') : '0';
};

export const formatDateString = (dateTime: string | Date): string => {
    return new Date(dateTime).toLocaleDateString('en-us');
};

export const getMonthsToNow = (): [string, string][] => {
    const months: string[] = [
        'December',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
    ];
    const monthValueLabels: [string, string][] = [];
    const now = new Date();
    const thisYear = now.getFullYear();
    const thisMonth = now.getMonth() + 1;
    // This is set to february for the client to test
    // This should get set back to March after that test
    let [month, year] = [2, 2023]; // Start of RP magic project

    while (year <= thisYear) {
        if (year === thisYear && month >= thisMonth) {
            year++;
            break;
        }
        const isoMonth = month < 10 ? `0${month}` : `${month}`;
        const monthValue = `${year}-${isoMonth}-01`;
        let monthLabel = `${months[month]}`;
        if (year < thisYear) monthLabel += ` ${year}`;

        monthValueLabels.push([monthValue, monthLabel]);

        month = (month + 1) % 12;
        if (month === 1) year++;
    }

    return monthValueLabels.reverse();
};

export const findPipeDiff = (pipes: string[], pipeDetails: PipeDetails): [string[], string[]] => {
    if (pipeDetails == undefined) {
        return [[], []];
    }

    const previousPipes = Object.keys(pipeDetails);
    const added = pipes.filter((x) => !previousPipes.includes(x));
    const removed = previousPipes.filter((x) => !pipes.includes(x));

    return [added, removed];
};

export const userIsAdmin = (user: MaybeUserType) => {
    if (!user) return null;
    return user.is_superadmin;
};
export const userIsStaff = (user: MaybeUserType) => {
    if (!user) return null;
    return user.is_staff;
};
export const userIsValidator = (user: MaybeUserType) => {
    if (!user) return null;
    return user.is_validator;
};

export const userCanEdit = (user: MaybeUserType) => {
    if (!user) return null;
    return user.can_edit;
};

export const createTileURLWithCacheKey = () => `/tile/pipes/${env('TILE_CACHE_KEY')}/{z}/{x}/{y}.pbf`;

export const areFiltersClosed = (mode: string) => mode === FILTER_MODES.closed;

export const createRangeOption = ({
    min,
    max,
    selected,
    delimiter,
}: {
    min: string;
    max: string;
    selected?: boolean;
    delimiter?: string;
}): OptionType => ({
    value: `${min}${delimiter ? delimiter : '-'}${max}`,
    label: `${min} - ${max}`,
    selected,
});

export const getMinMaxFromRangeOption = (option: OptionType, delimiter = '-'): { min: string; max: string } => {
    const minMax = { min: '', max: '' };
    if (option.value?.includes(delimiter)) {
        const values = option.value.split(delimiter);
        minMax.min = values[0] || '';
        minMax.max = values[1] || '';
    }
    return minMax;
};

export const isValidRangeOption = (option: OptionType | undefined) => {
    if (!option) return false;
    const { min, max } = getMinMaxFromRangeOption(option);
    return !isNaN(parseFloat(min)) && !isNaN(parseFloat(max));
};

export const isValidDateRangeOption = (option: OptionType | undefined) => {
    if (!option) return false;
    const { min, max } = getMinMaxFromRangeOption(option, '_');
    const isoDateFormat = /[\d][\d][\d][\d]-[01][\d]-[0123][\d]/;
    return isoDateFormat.test(min) && isoDateFormat.test(max);
};

type weightToVariable = Record<string, weightLabel[]>;
export const calculateDominantVariable = (project: ProjectMostDataType) => {
    const weights = Object.keys(projectWeightLabels).reduce((acc, weight) => {
        const value = project[weight as weightLabel];
        const labels = acc[`${value}`];
        if (labels) {
            return { ...acc, [`${value}`]: [...labels, weight] } as weightToVariable;
        } else {
            return { ...acc, [`${value}`]: [weight] } as weightToVariable;
        }
    }, {} as weightToVariable);

    const maximumValue = orderBy(Object.keys(weights), (w) => parseFloat(w), 'desc')[0];

    let maximumVariables = null;
    if (maximumValue) {
        maximumVariables = weights[maximumValue];
    }

    return formatList(
        maximumVariables?.map((w) => {
            const defaultLabel = projectWeightLabels[w] as investmentCategoryLabel;
            const projectLabels = project.categories.filter((c) => weightsToInvestmentCategoryLabels[w].includes(c));
            return !projectLabels.includes(defaultLabel) && projectLabels.length >= 1 ? projectLabels[0] : defaultLabel;
        }),
    );
};

const evaluateComparison = (a: number, b: number, operator: string) => {
    if (operator === 'minimum') {
        return a < b ? 1 : 0;
    } else if (operator === 'maximum') {
        return a > b ? 1 : 0;
    } else {
        return 0;
    }
};

export const getLimitException = (name: string, value: number | null, limits: ProjectLimitDataType[]) => {
    if (value == null) {
        return null;
    }
    const limitOfWeight = limits.find((limit) => limit.fieldname === name);
    if (limitOfWeight && evaluateComparison(value, limitOfWeight.value, limitOfWeight.minmax)) {
        return limitOfWeight;
    }
};

// Filter an array of limits to only include those relevant to a project type
export const filterLimitsToProjectType = (
    projectType: ProjectTypeType | undefined,
    limits: ProjectLimitDataType[],
): ProjectLimitDataType[] => {
    return projectType ? limits.filter((l) => l.project_types.includes(projectType)) : limits;
};

const getWeightLimitExceptions = (
    projectData: ProjectMostDataType | ProjectCsvResponseType,
    limits: ProjectLimitDataType[],
) => {
    const projectLimits = filterLimitsToProjectType(projectData.project_type, limits);
    const weightNames = uniq(projectData.categories.map((label) => investmentCategories[label]));
    return weightNames.reduce((exceptions: ProjectLimitDataType[], weightName) => {
        const limit = getLimitException(weightName, projectData[weightName], projectLimits);
        if (limit) {
            exceptions.push(limit);
        }
        return exceptions;
    }, []);
};

const getCalculatedLimitExceptions = (
    projectData: ProjectMostDataType | ProjectCsvResponseType,
    limits: ProjectLimitDataType[],
) => {
    const projectLimits = filterLimitsToProjectType(projectData.project_type, limits);
    const limitNames = ['total_impact_score', 'rehab_pipe_pct'];
    return limitNames.reduce((exceptions: ProjectLimitDataType[], limitName) => {
        const limit = getLimitException(
            limitName,
            projectData[limitName as keyof ProjectCsvResponseType] as number | null,
            projectLimits,
        );
        if (limit) {
            exceptions.push(limit);
        }
        return exceptions;
    }, []);
};

const getAllLimitExceptions = (
    projectData: ProjectMostDataType | ProjectCsvResponseType,
    limits: ProjectLimitDataType[],
) => [...getCalculatedLimitExceptions(projectData, limits), ...getWeightLimitExceptions(projectData, limits)];

export const getLimitExceptionLabels = (
    projectData: ProjectMostDataType | ProjectCsvResponseType,
    limits: ProjectLimitDataType[],
): string => {
    const labels = getAllLimitExceptions(projectData, limits).map((l) => limitExceptionLabels[l.fieldname]);
    return labels.length > 0 ? `${labels.join(', ')} out of range` : '';
};

export const getUniqueWeightLimitWarnings = (projectData: ProjectMostDataType, limits: ProjectLimitDataType[]) => {
    return uniq(getWeightLimitExceptions(projectData, limits).map((l) => l.warning_text));
};

export const getUniqueLimitWarnings = (projectData: ProjectMostDataType, limits: ProjectLimitDataType[]) => {
    return uniq(getAllLimitExceptions(projectData, limits).map((l) => l.warning_text));
};

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const formatAsUSD = (n: number, displayCents?: boolean) =>
    displayCents
        ? USDollar.format(n)
        : USDollar.formatToParts(Math.round(n))
              .slice(0, -2)
              .map(({ value }) => value)
              .join('');

export const formatList = <T>(list: T[] | null | undefined) => {
    if (!list || list.length === 0) return 'N/A';
    if (list.length === 1) return `${list[0]}`;
    const last = list.pop();
    return `${list.map((l) => l).join(', ')} and ${last}`;
};

export const StatusColors: Record<ProjectStatusType | MapcallStatusType, object> = {
    APPROVED: statusApproved,
    DENIED: statusDenied,
    PENDING: statusNeutral,
    AUTO_APPROVED: statusNeutral,
    DRAFT: statusNeutral,
    COMPLETE: statusDone,
    'AP Approved': statusApproved,
    'AP Endorsed': statusApproved,
    'Manager Endorsed': statusApproved,
    Complete: statusDone,
    Submitted: statusNeutral,
    Proposed: statusNeutral,
    Canceled: statusDenied,
};

export const formatDayDiff = (days: number | null): string | null => {
    if (days == null) return null;
    if (days === 0) return 'on time';
    return `${moment.duration(days, 'days').humanize()} ${days < 0 ? 'before' : 'past'}`;
};

export const formatAsPercentage = (fraction: number | null): string | null => {
    if (fraction == null) return null;
    return `${Math.round(fraction * 100)}%`;
};

export const calculateFraction = (numerator: number | null, denominator: number | null): number | null => {
    if (numerator == null || !denominator) return null;
    return numerator / denominator;
};

export const formatDuration = (days: number): string => {
    if (days >= 365 && days % 365 == 0) {
        const years = days / 365;
        return `${years.toFixed(0)} Year${years > 1 ? 's' : ''}`;
    }
    if (days >= 30 && days % 30 == 0) {
        const months = days / 30;
        return `${months.toFixed(0)} Month${months > 1 ? 's' : ''}`;
    }
    return `${days} Day${days > 1 ? 's' : ''}`;
};

export const projectDurationLabel = (project: ProjectMostDataType, { plural }: { plural?: boolean }): string | null => {
    let label: string | null = null;
    if (project.preset?.est_duration) {
        const duration = project.preset?.est_duration;
        const presetOption = presetEstDurationOptions.find(
            (presetOption) => presetOption.min <= duration && presetOption.max > duration,
        );
        label = presetOption?.label || null;
    }
    if (project.est_duration) {
        label = formatDuration(project.est_duration);
    }
    if (label && !plural) {
        label = label.replace(/s$/, '');
    }
    return label;
};

export const projectCostLabel = (project: ProjectMostDataType | ProjectCsvResponseType): string | null => {
    if (project.preset?.estimate) {
        const estimate = project.preset?.estimate;
        const presetOption = presetEstimateOptions.find(
            (presetOption) => presetOption.min <= estimate && presetOption.max > estimate,
        );
        return presetOption?.label || null;
    }
    if (project.estimate) {
        return formatAsUSD(project.estimate);
    }
    return null;
};

export const dateToQuarter = (date: Date): { year: number; quarter: number } => {
    const year = date.getUTCFullYear();
    const quarter = Math.floor(date.getUTCMonth() / 3) + 1;
    return { year, quarter };
};

export const quarterToDate = (year: number, quarter: number): { start: Date; end: Date } => {
    const start = new Date(year, (quarter - 1) * 3, 1);
    const end = new Date(year, quarter * 3, 0);
    return { start, end };
};

export const dateToStr = (date: Date): string => date.toISOString().split('T')[0] as string;

export const projectEstInServiceOptions = (): OptionType[] => {
    const today = new Date();
    let { quarter, year } = dateToQuarter(today);

    const quarters = [];
    for (let i = 0; i < 4; i++) {
        const { end } = quarterToDate(year, quarter);
        quarters.push({
            label: `Q${quarter} of ${year}`,
            value: dateToStr(end),
        });

        if (quarter === 4) {
            year++;
            quarter = 1;
        } else {
            quarter++;
        }
    }
    return quarters;
};

export const projectEstInServiceLabel = ({ est_in_service }: { est_in_service?: Date | string }): string | null => {
    if (est_in_service) {
        est_in_service = new Date(est_in_service);
        const { quarter, year } = dateToQuarter(est_in_service);
        return `Q${quarter} of ${year}`;
    }
    return null;
};

export const hexToRGBA = (hex: string): RGBTuple => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
};

export const rgbaToHex = (rgba: RGBTuple) => {
    const [r, g, b, a] = rgba;
    const hex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
    const hexa = a != undefined ? a.toString(16) : '';
    return `#${hex}${hexa}`;
};

// helper function to determine the max height of the gis mapcontrol
// based on whether or not the other mapcontrols are open
export const getGisMapControlMaxHeight = (isEditPage: boolean, keyOpen?: boolean, projectScoreIsOpen?: boolean) => {
    const headerBuffer = 200;
    if (isEditPage) {
        const keyBuffer = isEditPage && keyOpen ? 74 : 25;
        const scoreBuffer = isEditPage && projectScoreIsOpen ? 72 : 25;

        return `calc(100vh - ${headerBuffer + scoreBuffer + keyBuffer}px)`;
    }

    return `calc(100vh - ${headerBuffer}px)`;
};

export const getProjectWeights = (project: ProjectDataType): SelectedWeightsType => {
    const { categories } = project;
    const selectedCategories = [...new Set(categories.map((cat) => investmentCategories[cat]))];
    const weights = pick(project, selectedCategories);

    return weights;
};

export const blankStringIfNull = (obj: number | string | null | undefined): string =>
    isNull(obj) || isUndefined(obj) ? '' : String(obj);

export const projectToCsvRecord = (
    project: ProjectCsvResponseType,
    limits: ProjectLimitDataType[],
): ProjectCsvDataType => {
    const exceptions = getLimitExceptionLabels(project, limits);
    return {
        ['Project ID']: blankStringIfNull(project.universal_id),
        ['Project Title']: project.name,
        ['Score']: blankStringIfNull(project.total_impact_score),
        ['Project Status']: PROJECT_STATUS_DESCRIPTION[project.status],
        ['Operating Center']: project.district_name,
        ['Configuration']: project.categories.join(', '),
        ['F. Filing Period']: blankStringIfNull(project.foundational_filing_period),
        ['Regulatory Status']: blankStringIfNull(project.reg_status),
        ['Projected Cost Estimate']: blankStringIfNull(projectCostLabel(project)),
        ['Town']: blankStringIfNull(project.town_name),
        ['Creator']: project.created_by_email,
        ['Date Created']: project.created_at ? moment(project.created_at).format('MM/DD/YYYY') : '',
        ['Estimated In-service Date']: blankStringIfNull(projectEstInServiceLabel(project)),
        ['Actual In-service Date']: project.actual_in_service
            ? moment(project.actual_in_service).format('MM/DD/YYYY')
            : '',
        ['RP Project Type']: project.project_type as string,
        ['Base Rate']: blankStringIfNull(project.base_rate),
        ['Diam. being Replaced']: blankStringIfNull(project.prop_diameter),
        ['Mat. being Replaced']: blankStringIfNull(project.prop_material),
        ['Total Length']: blankStringIfNull(project.total_length),
        ['Asset Category']: project.asset_category as string,
        ['Asset Type']: project.asset_type ? project.asset_type.join(', ') : '',
        ['High Cost Factors']: project.high_cost_factors ? project.high_cost_factors.join(', ') : '',
        ['Exceptions']: exceptions,
    };
};
